import { toastr } from 'react-redux-toastr';
import Util from '../../helper/Util';
import jwtService from '../../services/jwtService';
import * as Actions from './actionTypes';
import { getFavorites } from './favorites.actions';
import { withdrawTransactionTypes, depositPollingInterval, depositPollingTimer } from '../../config';
import { dynamoClient } from '../../App';
import { userBalanceUpdate } from '../../dynamo/myAccountParams';

let getUserInterval = null;

//Register user
export const submitSignUp = (obj) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let userData = {
            username: obj.inputUsername,
            password: obj.inputPassword,
            confirm_password: obj.inputConfirmPassword,
            currency: obj.currencyName,
            email: obj.inputEmail,
            dob: obj.inputDob,
            phone_number: obj.phone_number,
            first_name: obj.inputName,
            last_name: obj.inputSurname,
            //   agent_code: "13",
            //   country_code: " ",
        };
        jwtService
            .signup(userData, language)
            .then((user) => {
                toastr.success('', 'signin successfull');
                dispatch(userdata(user.user_data));
                dispatch(submitLogin(obj.inputUsername, obj.inputPassword));
                dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'success' });
                localStorage.removeItem('refCode');
                localStorage.removeItem('firstRefCodeHit');
                setTimeout(() => {
                    dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                }, 1000);
            })
            .catch((error) => {
                toastr.error('', error.response.data.message);
                dispatch({ type: Actions.SIGNUP_ERROR, signupError: error.response.data.message });
                setTimeout(() => {
                    dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                }, 1000);
            });
    };
};

export const openOtpForm = (val) => {
    return (dispatch) => {
        dispatch({ type: Actions.OPEN_OTP_FORM_SIGNUP, data: val });
    };
};

export const submitSignInOtp = (otp) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let data = getState().user.user_data;
        const userData = JSON.parse(data);
        const username = userData.username;
        const password = userData.password;
        jwtService.verifyOtp(otp, 'signup', data, language).then((user) => {
            toastr.success('', 'signin successfull');
            dispatch(submitLogin(username, password));
            dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'successfull' });
            dispatch(openOtpForm(false));
        })
            .catch((error) => {
                dispatch({ type: Actions.SIGNUP_ERROR, signupError: error?.response?.data?.message });
                toastr.error('', error?.response?.data?.message);
            });
    };
};

export const getOtp = (phone, password, code, type) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        dispatch(setUserDetails(phone, password));
        jwtService.getOtp(phone, code, type, language).then((user) => {
            toastr.success('', 'Otp send Successfully');
            dispatch(openOtpLogin(true));
            dispatch(userdata(user.user_data));
            dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: 'sucess' });
            setTimeout(() => {
                dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
            }, 1000);
        })
            .catch((error) => {
                dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: error?.response?.data?.message });
                toastr.error('', error?.response?.data?.message);
                setTimeout(() => {
                    dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                }, 1000);
            });
    };
};
export const setUserDetails = (phone, password) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_USER_DETAILS, details: { phone, password } });
    };
};
export const getOtpWithDraw = (type, amount, moncashNumber, country_code) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        dispatch({ type: Actions.SET_AMOUNT_MONCASHNUMBER, amount: amount, moncashNumber: moncashNumber });
        jwtService.getOtpWithDraw(type, language, moncashNumber, country_code).then((user) => {
            toastr.success('', 'Otp send Successfully');
            dispatch(openOtpWithdrawForm(true));
            dispatch(userdata(user.user_data));
            dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: 'sucess' });
            setTimeout(() => {
                dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
            }, 1000);
        })
            .catch((error) => {
                dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: error?.response?.data?.message });
                toastr.error('', error?.response?.data?.message);
                setTimeout(() => {
                    dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                }, 1000);
            });
    };
};

export const withdrawAmount = ({ amount, currency, buyer_email, address, history, transactionType, setError }) => {
    return (dispatch, getState) => {
        if (transactionType == 'online') {
            jwtService.withdrawOnline({ amount, currency, buyer_email, address })
                .then((data) => {
                    if (data?.txn_id) {
                        toastr.success('', 'Withraw request completed');
                        dispatch(setWithDrawSuccess(true));
                        dispatch(getUser());
                        history.push('/d/my-account/transactions');
                    } else {
                        // toastr.error('', data?.message || 'Withdraw request failed');
                        setError(data?.message || 'Withdraw request failed')
                        dispatch(getUser());
                        dispatch(setWithDrawSuccess(false));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(setWithDrawSuccess(false));
                });
        } else {
            let language = getState().general.language;
            jwtService.withdrawOffline({ amount, language })
                .then((data) => {
                    toastr.success('', data.message);
                    dispatch(setWithDrawSuccess(true));
                    dispatch(getUser());
                })
                .catch((error) => {
                    console.log(error);
                    setError(error?.message || 'Withdraw request failed')
                    dispatch(getUser());
                    dispatch(setWithDrawSuccess(false));
                });
        }
    };
};

export const submitWithDrawOtp = (otp) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let data = getState().user.user_data;
        let amount = getState().user.amount;
        let moncashNumber = getState().user.moncashNumber;
        jwtService.verifyOtp(otp, 'withdraw', data, language).then((user) => {
            dispatch(setWithDrawSuccess(true));
            dispatch(openOtpWithdrawForm(false));
            dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: 'success' });
            jwtService.withdraw(amount, moncashNumber).then((res) => {
                toastr.success(res[1].message.message);
                dispatch(getUser(null));
            })
                .catch((error) => {
                    toastr.error(error.response.data[1].message.message);
                    console.log(error);
                });
            setTimeout(() => {
                dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: '' });
            }, 1000);
        })
            .catch((error) => {
                dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: error?.response?.data?.message });
                toastr.error('', error?.response?.data?.message);
                setTimeout(() => {
                    dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: '' });
                }, 1000);
            });
    };
};

export const setWithDrawSuccess = (val) => {
    return (dispatch) => {
        dispatch({ type: Actions.SUCCESS_WITHDRAW, val });
    };
};
export const openOtpWithdrawForm = (val) => {
    return (dispatch) => {
        dispatch({ type: Actions.OPEN_OTP_FORM_WITHDRAW, val: val });
    };
};

export const openOtpLogin = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: Actions.OPEN_OTP_FORM_LOGIN, data: data });
    };
};

export const submitForgetOtp = (otp) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let data = getState().user.user_data;
        let phone = getState().user.setUserDetails.phone;
        let password = getState().user.setUserDetails.password;
        jwtService.verifyOtp(otp, 'forgot-password', data, language).then((res) => {
            dispatch(openOtpLogin(false));
            jwtService.forgetPassword(phone, password, language).then((data) => {
                toastr.success('', 'password change successfully');
            }).catch((err) => {
                toastr.error('', err.response.data.message);
            });
            dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: 'success' });
            setTimeout(() => {
                dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: '' });
            }, 1000);

        })
            .catch((error) => {
                toastr.error('', error.response.data.message);
                dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: error.response.data.message });
                setTimeout(() => {
                    dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: '' });
                }, 1000);

            });
    };
};

const userdata = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.USER_DATA, data: data });
    };
};

// Login user
export const submitLogin = (username, password, rememberMe) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService
            .signInWithUsernameAndPassword(username, password, rememberMe, language)
            .then((user) => {
                localStorage.setItem('user_id', user.pk);
                localStorage.setItem('requestToken', user?.user_sport_token)
                jwtService.getUser(null, language).then((user) => {
                    dispatch({ type: Actions.SET_USER, user });
                    dispatch(getFavorites());
                });
                // if (Util.isMobileCheck())  window.location.href = ('/d');
                // else  window.location.href = ('/d');
                return dispatch({ type: Actions.LOGIN_SUCCESS });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(setLoginError(error.response.data?.non_field_errors && error.response.data?.non_field_errors[0]));
                }
            });
    };
};

// Set login error
export const setLoginError = (error) => {
    return (dispatch) => {
        dispatch({
            type: Actions.LOGIN_ERROR,
            loginError: error,
        });
        setTimeout(() => {
            dispatch({
                type: Actions.LOGIN_ERROR,
                loginError: '',
            });
        }, 7000);
    };
};

// Change user password
export const changePassword = (oldPassword, newPassword) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService
            .changeUserPassword(oldPassword, newPassword, language)
            .then((response) => {
                dispatch(setChangePasswordSuccess(true));
                toastr.success('', 'Password was changed successfully!');
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error(error.response.data.msg);
                    dispatch(setChangePasswordError(error.response.data.msg));
                }
            });
    };
};

//Deposit
export const depositAmount = (payload) => {
    return (dispatch) => {
        //  const oauthPopup =  window.open();
        jwtService
            .deposit(payload)
            .then((data) => {
                if (data.result?.txn_id) {
                    const url = data.result.checkout_url;
                    toastr.success('', 'Deposit initiated successfully');
                    sessionStorage.setItem('depositPollingTimer', new Date().getTime() + depositPollingTimer);
                    dispatch(setDepositSuccess(true));
                    dispatch(setDepositError('success'));
                    dispatch(depositPolling());
                    setTimeout(() => {
                        dispatch(setDepositError(''));
                    }, 1000);
                    window.open(url, '_blank');
                } else {
                    dispatch(setDepositError('failed'));
                    toastr.error('', data.error);
                    setTimeout(() => {
                        dispatch(setDepositError(''));
                    }, 1000);
                }
            })
            .catch((error) => {
                console.error(error);
                dispatch(setDepositError('failed'));
                toastr.error('', error?.response?.data?.detail[0]?.msg || 'Only integers accepted for the deposit amount');
                setTimeout(() => {
                    dispatch(setDepositError(''));
                }, 1000);
            });
    };
};

export const setDepositSuccess = (val) => {
    return (dispatch) => {
        dispatch({ type: Actions.SUCCESS_DEPOSIT, val });
    };
};

export const setDepositError = (error) => {
    return (dispatch) => {
        dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error });
    };
};

export const resetDepositAmount = () => {
    return (dispatch) => {
        dispatch({ type: Actions.RESET_DEPOSIT_AMOUNT });
    };
};

// Set change password success
export const setChangePasswordSuccess = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_SUCCESS, value });
    };
};

// Set change password error
export const setChangePasswordError = (error) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_ERROR, error });
    };
};

// Logout user
export const logoutUser = () => {
    return (dispatch) => {
        jwtService.logout();
        dispatch({ type: Actions.SET_FAVORITES, events: [] });
        dispatch({ type: Actions.SET_FAVORITES_LIVE, events: [] });
        dispatch({ type: Actions.LOGOUT_USER });
    };
};

// Set user object data
export const setUser = (user) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_USER, user });
    };
};

// Get user object data
export const getUser = (balance_read) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService.getUser(balance_read, language).then((user) => {
            dispatch(setUser(user));
        });
    };
};

export const getCashbackData = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.getCashbackData(userId).then((response) => {
            dispatch(setCashback(response));
        });
    };
};

export const setCashback = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_DATA, data });
    };
};

export const getCashbackSucess = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.setCashback(parseInt(userId)).then((response) => {
            dispatch(setCashbackSucess(response));

        })
            .catch((error) => {
                console.error(error);
            });

    };
};

export const setCashbackSucess = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_SUCESS, data });
        toastr.success('', data.message);
        dispatch(getUser());
    };
};

export const setOddType = (oddType) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_ODD_TYPE, oddType });
    };
};

export const depositPolling = () => {
    return (dispatch, getState) => {
        // const user_id = localStorage.getItem('user_id');
        getUserInterval = setInterval(() => {
            dispatch(getUser(true));

            if (sessionStorage.getItem('depositPollingTimer') <= new Date().getTime()) {
                clearInterval(getUserInterval);
            }
        }, depositPollingInterval); // 1 min
    };
};

export const checkForPreviousDeposit = () => {
    return (dispatch) => {
        if (sessionStorage.getItem('depositPollingTimer') && sessionStorage.getItem('depositPollingTimer') > new Date().getTime()) {
            dispatch(depositPolling());
        }
    };
};

export const getTransactionStatus = (transactionId) => {
    return (dispatch) => {
        jwtService.getTransactionStatus(transactionId).then((res) => {
            dispatch(setTransactionDetail(res));
            dispatch({ type: Actions.SET_TRANSACTION_LOADING, val: false });
        })
            .catch((error) => {
                dispatch({ type: Actions.SET_TRANSACTIONS_STATUS_ERROR, val: 'something went wrong' });
                dispatch({ type: Actions.SET_TRANSACTION_LOADING, val: false });
            });
    };
};

const setTransactionDetail = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_TRANSACTION_DETAIL, data: data });
    };
};

//update profile
export const updateProfile = (data, newPassword) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService
            .updateProfile(data, language)
            .then((response) => {
                toastr.success('', 'Profile updated was changed successfully!');
                dispatch(getUser());
            })
            .catch((error) => {
                if (
                    error &&
                    error.response &&
                    error.response.status &&
                    error.response.status === 401
                ) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error(error.response.data.msg);
                }
            });
    };
};

export const uploadProfilePicture = (data) => {
    console.log(data);
    return (dispatch) => {
        jwtService.uploadProfilePic(data)
            .then((res) => dispatch(getUser()))
            .catch(err => console.error(err))
    }
}